import SectionList from './SectionList';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { shadows } from '@mui/system';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

function DivisionShow({ divisionName, divisionColor }) {
  //console.log(divisionColor);
  return (
    <Root>
      <Divider
        sx={{
          '&.MuiDivider-root': {
            '&::before': {
              borderTop: `thin solid white`,
            },
            '&::after': {
              borderTop: `thin solid white`,
            },
          },
        }}
        style={{
          color: 'white',
          padding: '0 0 30px 0',
        }}
        variant="middle"
      >
        <Chip
          label={divisionName}
          sx={{
            color: 'black',
            backgroundColor: divisionColor,
            fontWeight: 'bold',
            fontSize: '14px',
            fontFamily: 'Arial',
          }}
        />
      </Divider>
    </Root>
  );
}

export default DivisionShow;

import { useEffect, useState } from 'react';
import TheBuddyGroupLogo from '../images/TBG_Logo_Horizontal_white_01.png';
import { loadCSS } from 'fg-loadcss';
import { Box, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#28313d',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  boxShadow: 'none',
}));

function SearchBar({ onSubmit, isLoggedin, onLogout }) {
  useEffect(() => {
    handleClick();
    handleChange();
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v6.3.0/css/all.css',
      // Inject before JSS
      document.querySelector('#font-awesome-css') || document.head.firstChild
    );
  }, []);

  const handleClick = () => {
    onSubmit();
  };

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  return (
    <Box>
      <Grid container>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <Item>
            {isLoggedin ? (
              <Slide
                direction="right"
                in={checked}
                mountOnEnter
                unmountOnExit
                {...(checked ? { timeout: 2600 } : {})}
              >
                <img
                  src={TheBuddyGroupLogo}
                  style={{
                    width: '150px',
                    padding: '20px 0 0 20px',
                  }}
                ></img>
              </Slide>
            ) : (
              <div></div>
            )}
          </Item>
        </Grid>
        <Grid
          item
          xs={3}
          sm={4}
          md={5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <Item>
            {isLoggedin ? (
              <Fade
                in={checked}
                mountOnEnter
                unmountOnExit
                {...(checked ? { timeout: 2600 } : {})}
              >
                <Button onClick={handleClick} style={{ padding: '20px 0 0 0' }}>
                  Refresh Data
                </Button>
              </Fade>
            ) : (
              <div></div>
            )}
          </Item>
        </Grid>
        <Grid
          item
          xs={3}
          sm={2}
          md={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <Item>
            {isLoggedin ? (
              <Slide
                direction="left"
                in={checked}
                mountOnEnter
                unmountOnExit
                {...(checked ? { timeout: 2600 } : {})}
              >
                <Button onClick={onLogout} style={{ padding: '20px 20px 0 0' }}>
                  Logout
                </Button>
              </Slide>
            ) : (
              <div></div>
            )}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SearchBar;

import TaskCalendarShow from './TaskCalendarShow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
//import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { formatISO, format, addDays } from 'date-fns';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#CCCC',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
  },
}));

const TableContainer = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
}));

function TaskCalendar({ tasks, first_monday }) {
  const theTasks = tasks.map((task) => {
    return <TaskCalendarShow key={task.gid} task={task} />;
  });

  //console.log(new Date(first_monday).getUTCFullYear());
  //console.log(new Date(first_monday).getUTCMonth());
  //console.log(new Date(first_monday).getUTCDate());

  let d = new Date(
    new Date(first_monday).getUTCFullYear(),
    new Date(first_monday).getUTCMonth(),
    new Date(first_monday).getUTCDate()
  );

  first_monday = new Date(d)
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  //let a = d.getDate();
  //console.log(format(new Date(d.setDate(a + 1)), 'MM/dd/yyyy'));
  //let first_tuesday = formatISO(new Date(d.setDate(a + 1)));
  let first_tuesday = new Date(addDays(new Date(d), 1))
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  let first_wednesday = new Date(addDays(new Date(d), 2))
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  let first_thursday = new Date(addDays(new Date(d), 3))
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  let first_friday = new Date(addDays(new Date(d), 4))
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  let first_saturday = new Date(addDays(new Date(d), 5))
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  let first_sunday = new Date(addDays(new Date(d), 6))
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  let second_monday = new Date(addDays(new Date(d), 7))
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  let second_tuesday = new Date(addDays(new Date(d), 8))
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  let second_wednesday = new Date(addDays(new Date(d), 9))
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  let second_thursday = new Date(addDays(new Date(d), 10))
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  let second_friday = new Date(addDays(new Date(d), 11))
    .toISOString()
    .substring(0, 10)
    .substring(6, 11)
    .replace('-', '/');

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="a dense table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }}
            >
              Task
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }}
            >
              Assignee
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              M {first_monday}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              T {first_tuesday}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              W {first_wednesday}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              T {first_thursday}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              F {first_friday}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                backgroundColor: '#7d7a7a',
                color: 'white',
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              S {first_saturday}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                backgroundColor: '#7d7a7a',
                color: 'white',
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              S {first_sunday}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              M {second_monday}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              T {second_tuesday}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              W {second_wednesday}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              T {second_thursday}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{
                borderLeft: '1px solid rgba(224, 224, 224, 1)',
                width: '20px',
              }}
            >
              F {second_friday}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{theTasks}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default TaskCalendar;

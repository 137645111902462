import { useState } from 'react';
import SearchBar from './components/SearchBar';
import { searchProjects, loginPassword } from './api2';
import SectionList from './components/SectionList';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Login from './components/Login';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  borderWidth: 0,
}));

function App() {
  const [sections, setSections] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isLoggedin, setIsLoggedin] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const result = await searchProjects();
    setIsLoading(false);
    setSections(result);
  };

  const handleLogin = async (inputPassword) => {
    setIsLoggedin(false);
    const result = await loginPassword();
    if (inputPassword == result) {
      setIsLoggedin(true);
    }
    /*const result = await searchProjects();
    setIsLoading(false);
    setSections(result);*/
  };

  const handleLogout = () => {
    setIsLoggedin(false);
  };

  return (
    <div>
      <SearchBar
        onSubmit={handleSubmit}
        isLoggedin={isLoggedin}
        onLogout={handleLogout}
      />
      <br />
      <br />
      {isLoggedin ? (
        <div>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '500px',
              }}
            >
              <CircularProgress style={{ color: 'white' }} />
            </Box>
          ) : (
            <div>
              {sections[0].length > 0 ? (
                <SectionList
                  sections={sections[0]}
                  sectionName={'Storydriven Way'}
                  sectionDividerColor={'#e3f760'}
                />
              ) : (
                <div></div>
              )}
              {sections[1].length > 0 ? (
                <SectionList
                  sections={sections[1]}
                  sectionName={'Content Production'}
                  sectionDividerColor={'#61f481'}
                />
              ) : (
                <div></div>
              )}
              {sections[2].length > 0 ? (
                <SectionList
                  sections={sections[2]}
                  sectionName={'IXL'}
                  sectionDividerColor={'#7efce9'}
                />
              ) : (
                <div></div>
              )}
              {sections[3].length > 0 ? (
                <SectionList
                  sections={sections[3]}
                  sectionName={'Experience Management'}
                  sectionDividerColor={'#ffc054'}
                />
              ) : (
                <div></div>
              )}
              {sections[4].length > 0 ? (
                <SectionList
                  sections={sections[4]}
                  sectionName={'In-House'}
                  sectionDividerColor={'#2ec998'}
                />
              ) : (
                <div></div>
              )}
              {sections[5].length > 0 ? (
                <SectionList
                  sections={sections[5]}
                  sectionName={'TBG Internal'}
                  sectionDividerColor={'#5e7cc9'}
                />
              ) : (
                <div></div>
              )}
              {sections[6].length > 0 ? (
                <SectionList
                  sections={sections[6]}
                  sectionName={'Biz Dev'}
                  sectionDividerColor={'#2b912a'}
                />
              ) : (
                <div></div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <Login onSubmit={handleLogin} />
        </div>
      )}
    </div>
  );
}

export default App;

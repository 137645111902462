import { useEffect, useState } from 'react';
import SectionShow from './SectionShow';
import DivisionShow from './DivisionShow';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { shadows } from '@mui/system';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) + :not(style)': {
    marginTop: theme.spacing(2),
  },
}));

function SectionList({ sections, sectionName, sectionDividerColor }) {
  useEffect(() => {
    handleChange();
  }, []);

  const theSections = sections.map((section) => {
    return <SectionShow key={section.gid} section={section} />;
  });

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  return (
    <Grow
      in={checked}
      style={{ transformOrigin: '0 0 0' }}
      {...(checked ? { timeout: 2000 } : {})}
    >
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ paddingBottom: '100px' }}
      >
        <DivisionShow
          divisionName={sectionName}
          divisionColor={sectionDividerColor}
        />
        {theSections}
      </Grid>
    </Grow>
  );
}

{
  // DIVIDER CODE FOR DIVISIONS
  /*<Root>
      <Divider
        sx={{
          '&.MuiDivider-root': {
            '&::before': {
              borderTop: `thin solid white`,
            },
            '&::after': {
              borderTop: `thin solid white`,
            },
          },
        }}
        style={{
          color: 'white',
        }}
        variant="middle"
      >
        <Chip
          label="Storydriven Way"
          sx={{
            color: 'black',
            backgroundColor: 'white',
            fontWeight: 'bold',
            fontSize: '14px',
            fontFamily: 'Arial',
          }}
        />
        </Divider>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {theSections}
      </Grid>
    {/*</Root>*/
}

export default SectionList;

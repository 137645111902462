import TaskShow from './TaskShow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
//import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#CCCC',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
  },
}));

const TableContainer = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
}));

function TaskList({ tasks }) {
  const theTasks = tasks.map((task) => {
    return <TaskShow key={task.gid} task={task} />;
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%' }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Task</StyledTableCell>
            <StyledTableCell align="center">Due On</StyledTableCell>
            <StyledTableCell align="right">Assignee</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{theTasks}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default TaskList;

import { useEffect, useState } from 'react';
import { Paper, Box, Button } from '@mui/material';
import Grow from '@mui/material/Grow';
import TextField from '@mui/material/TextField';

function Login({ onSubmit }) {
  useEffect(() => {
    handleTransitionChange();
  }, []);

  const [checked, setChecked] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');

  const handleTransitionChange = () => {
    setChecked((prev) => !prev);
  };

  const handleChange = (event) => {
    setPasswordInput(event.target.value);
    //console.log(passwordInput);
  };

  const handleLogin = (event) => {
    //console.log(passwordInput);
    event.preventDefault();
    onSubmit(passwordInput);
    setPasswordInput('');
  };

  return (
    <Box
      style={{ justifyContent: 'center' }}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: '300px',
          height: '80px',
        },
      }}
    >
      <Grow
        in={checked}
        appear
        style={{
          transformOrigin: '0 0 0',
          transitionDelay: checked ? '300ms' : '0ms',
        }}
        {...(checked ? { timeout: 1500 } : {})}
      >
        <Paper
          elevation={3}
          style={{
            paddingLeft: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#1b222a',
          }}
        >
          <form
            onSubmit={handleLogin}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/*<input value={passwordInput} onChange={handleChange}></input>*/}
            <TextField
              id="outlined-password-input"
              label="Password"
              type="password"
              value={passwordInput}
              onChange={handleChange}
              autoComplete="current-password"
              color="primary"
              focused
              variant="outlined"
              sx={{ input: { color: 'white' } }}
            />
            <Button
              type="submit"
              variant="outlined"
              style={{ margin: '0 10px 0 10px' }}
              size="small"
            >
              Submit
            </Button>
            {/*<button>Submit</button>*/}
          </form>
        </Paper>
      </Grow>
    </Box>
  );
}

export default Login;

import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
//import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { red, yellow } from '@mui/material/colors';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import Icon from '@mui/material/Icon';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  /*'&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },*/
}));

function TaskCalendarShow({ task }) {
  const theDoneIconSize = 26;
  let taskFontWeight = 'bold';
  let taskFontColor = 'black';
  let taskBackgroundColor = '#fff';
  let iconColor = 'green';

  if (task.name.includes(' - - ')) {
    taskFontWeight = 'normal';
    taskBackgroundColor = '#f9f9f9';
    iconColor = '#73bcb9';
  }

  if (task.name.includes('-  ')) {
    taskFontWeight = 'bold';
    taskFontColor = 'white';
    taskBackgroundColor = '#959494';
  }

  let theCellM1 = {
    backgroundColor: '',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconM1 = { display: 'none' };
  let theCellTu1 = {
    backgroundColor: '',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconTu1 = { display: 'none' };
  let theCellW1 = {
    backgroundColor: '',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconW1 = { display: 'none' };
  let theCellTh1 = {
    backgroundColor: '',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconTh1 = { display: 'none' };
  let theCellF1 = {
    backgroundColor: '',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconF1 = { display: 'none' };
  let theCellSa = {
    backgroundColor: '#7d7a7a',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconSa = { display: 'none' };
  let theCellSu = {
    backgroundColor: '#7d7a7a',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconSu = { display: 'none' };
  let theCellM2 = {
    backgroundColor: '',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconM2 = { display: 'none' };
  let theCellTu2 = {
    backgroundColor: '',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconTu2 = { display: 'none' };
  let theCellW2 = {
    backgroundColor: '',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconW2 = { display: 'none' };
  let theCellTh2 = {
    backgroundColor: '',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconTh2 = { display: 'none' };
  let theCellF2 = {
    backgroundColor: '',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  };
  let theIconF2 = { display: 'none' };
  if (task.m1 != null) {
    theCellM1 = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconM1 = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }
  if (task.tu1 != null) {
    theCellTu1 = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconTu1 = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }
  if (task.w1 != null) {
    theCellW1 = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconW1 = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }
  if (task.th1 != null) {
    theCellTh1 = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconTh1 = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }
  if (task.f1 != null) {
    theCellF1 = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconF1 = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }
  if (task.sa != null) {
    theCellSa = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconSa = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }
  if (task.su != null) {
    theCellSu = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconSu = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }
  if (task.m2 != null) {
    theCellM2 = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconM2 = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }
  if (task.tu2 != null) {
    theCellTu2 = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconTu2 = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }
  if (task.w2 != null) {
    theCellW2 = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconW2 = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }
  if (task.th2 != null) {
    theCellTh2 = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconTh2 = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }
  if (task.f2 != null) {
    theCellF2 = {
      backgroundColor: '',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    };
    theIconF2 = {
      color: iconColor,
      verticalAlign: 'middle',
      fontSize: theDoneIconSize,
    };
  }

  return (
    <StyledTableRow style={{ backgroundColor: taskBackgroundColor }}>
      <StyledTableCell
        style={{
          borderLeft: '1px solid rgba(224, 224, 224, 1)',
          fontWeight: taskFontWeight,
          color: taskFontColor,
        }}
      >
        {task.name}
      </StyledTableCell>
      <StyledTableCell
        align="center"
        style={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }}
      >
        {task.assignee != null && task.assignee.name}
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellM1}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconM1}
        ></Icon>
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellTu1}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconTu1}
        ></Icon>
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellW1}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconW1}
        ></Icon>
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellTh1}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconTh1}
        ></Icon>
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellF1}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconF1}
        ></Icon>
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellSa}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconSa}
        ></Icon>
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellSu}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconSu}
        ></Icon>
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellM2}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconM2}
        ></Icon>
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellTu2}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconTu2}
        ></Icon>
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellW2}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconW2}
        ></Icon>
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellTh2}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconTh2}
        ></Icon>
      </StyledTableCell>
      <StyledTableCell align="center" style={theCellF2}>
        <Icon
          baseClassName="fas"
          className="fa-calendar-check"
          sx={theIconF2}
        ></Icon>
      </StyledTableCell>
    </StyledTableRow>
  );
}

export default TaskCalendarShow;

import * as React from 'react';
import parse from 'html-react-parser';
import TaskCalendar from './TaskCalendar';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import EventNoteSharpIcon from '@mui/icons-material/EventNoteSharp';
import InstaganttIcon from '../images/instagantt-icon.png';
import AsanaLogo from '../images/asana-dots.png';
import PipeDriveLogo from '../images/Pipedrive-Symbol2.png';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TaskList from './TaskList';
import {
  zonedTimeToUtc,
  utcToZonedTime,
  format,
  formatISO,
  formatInTimeZone,
} from 'date-fns-tz';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#28313d',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '18px',
  },
}));

function SectionShow({ section }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let expandTooltip;
  if (expanded == expanded) {
    expandTooltip = 'Status Update & Next Invoice';
  } else {
    expandTooltip = 'Close';
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openBrief, setOpenBrief] = React.useState(false);
  const handleOpenBrief = () => setOpenBrief(true);
  const handleCloseBrief = () => setOpenBrief(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let modalWidth = 1300;
  let modalMaxHeight = '80vh';

  if (isMobile) {
    modalWidth = '100%';
    modalMaxHeight = '60vh';
  }

  let style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: modalWidth,
    maxHeight: modalMaxHeight,
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  let logoPadding = '0px';
  if (section.brief != null && section.brief != '' && !isMobile) {
    logoPadding = '74px';
  }

  let theIcon;
  let theMessage;
  if (section.status_color === 'green') {
    //theIcon = <ThumbUpAltRoundedIcon />;
    theIcon = (
      <Icon
        baseClassName="fas"
        className="fa-thumbs-up"
        sx={{ fontSize: 22, paddingLeft: '1px' }}
      />
    );
    section.status_color2 = '#58A182';
    theMessage = 'On Track';
  } else if (section.status_color === 'yellow') {
    //theIcon = <HorizontalRuleSharpIcon />;
    theIcon = (
      <Icon
        baseClassName="fas"
        className="fa-minus"
        sx={{ fontSize: 26, paddingLeft: '1px' }}
      />
    );
    section.status_color2 = '#F1BD6C';
    theMessage = 'At Risk';
  } else if (section.status_color === 'orange') {
    //theIcon = <HorizontalRuleSharpIcon />;
    theIcon = (
      <Icon
        baseClassName="fas"
        className="fa-minus"
        sx={{ fontSize: 26, paddingLeft: '1px' }}
      />
    );
    section.status_color2 = '#F1BD6C';
    theMessage = 'At Risk';
  } else if (section.status_color === 'red') {
    //theIcon = <ThumbDownAltRoundedIcon />;
    theIcon = (
      <Icon
        baseClassName="fas"
        className="fa-thumbs-down"
        sx={{ fontSize: 22, paddingLeft: '1px' }}
      />
    );
    section.status_color2 = '#DE5F73';
    theMessage = 'Off Track';
  } else if (section.status_color === 'blue') {
    //theIcon = <HorizontalRuleSharpIcon />;
    theIcon = (
      <Icon
        baseClassName="fas"
        className="fa-pause"
        sx={{ fontSize: 26, paddingLeft: '0px' }}
      />
    );
    section.status_color2 = '#3F6AC4';
    theMessage = 'On Hold';
  } else if (section.status_color === 'complete') {
    //theIcon = <HorizontalRuleSharpIcon />;
    theIcon = (
      <Icon
        baseClassName="fas"
        className="fa-check-double"
        sx={{ fontSize: 26, paddingLeft: '0px' }}
      />
    );
    section.status_color2 = '#f1a2f9';
    theMessage = 'Complete!';
  } else if (section.status_color === 'white') {
    //theIcon = <HorizontalRuleSharpIcon />;
    theIcon = (
      <Icon
        baseClassName="fas"
        className="fa-question"
        sx={{ fontSize: 26, paddingLeft: '2px' }}
      />
    );
    section.status_color2 = '#BDBDBD';
    theMessage = '?';
  }

  if (section.completion_date != null) {
    section.completion_date = section.completion_date + ' 00:00';
    section.completion_date = new Date(section.completion_date);
    section.completion_date = format(section.completion_date, 'd MMMM, yyyy');
  }

  let bizDevBlock = false;
  if (section.gid == '1204219622955400') {
    bizDevBlock = true;
    theIcon = (
      <Icon
        baseClassName="fas"
        className="fa-sack-dollar"
        sx={{ fontSize: 22, paddingLeft: '0px' }}
      />
    );
    section.status_color2 = '#2b912a';
    theMessage = 'Always Be Closing!';
  }

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ boxShadow: 0 }}>
      <Item>
        <Card variant="outlined">
          <CardHeader
            align="left"
            avatar=<div style={{ display: 'flex' }}>
              <BootstrapTooltip title={theMessage} placement="right">
                <Avatar sx={{ bgcolor: section.status_color2 }}>
                  {theIcon}
                </Avatar>
              </BootstrapTooltip>
              {section.brief != null && section.brief != '' ? (
                <div style={{ width: logoPadding, height: '100%' }}></div>
              ) : (
                <div></div>
              )}
            </div>
            title=<CardMedia
              component="img"
              image={section.client_logo}
              height="50"
              sx={{
                padding: '0',
                objectFit: 'contain',
                marginLeft: '0px',
              }}
            />
            action=<div>
              {section.brief != null && section.brief != '' ? (
                <BootstrapTooltip title="View Brief" placement="left">
                  <IconButton
                    aria-label="brief"
                    onClick={handleOpenBrief}
                    style={{ padding: '2px, 0, 0, 0' }}
                  >
                    <SummarizeIcon
                      style={{
                        color: '#7b7c7d',
                        fontSize: '50px',
                        padding: '4px, 0, 0, 0',
                      }}
                    />
                  </IconButton>
                </BootstrapTooltip>
              ) : (
                <div></div>
              )}
              {bizDevBlock == true ? (
                <BootstrapTooltip
                  title="View Pipedrive Dashboard"
                  placement="bottom-end"
                >
                  <IconButton
                    aria-label="PipeDrive"
                    onClick={() =>
                      window.open(
                        'https://thebuddygroup.pipedrive.com/pipeline/1/filter/1'
                      )
                    }
                    style={{ padding: '2px, 0, 0, 0' }}
                  >
                    <img
                      src={PipeDriveLogo}
                      alt="PipeDrive"
                      width={'45px'}
                      height={'45px'}
                    />
                  </IconButton>
                </BootstrapTooltip>
              ) : (
                <BootstrapTooltip title="View Calendar" placement="bottom-end">
                  <IconButton
                    aria-label="calendar"
                    onClick={handleOpen}
                    style={{ padding: '2px, 0, 0, 0' }}
                  >
                    <EventNoteSharpIcon
                      style={{
                        color: '#5471f9',
                        fontSize: '50px',
                        padding: '4px, 0, 0, 0',
                      }}
                    />
                  </IconButton>
                </BootstrapTooltip>
              )}
            </div>
          />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            style={{
              backgroundColor: 'rgb(0 0 0 / 85%)',
              overflowY: 'scroll',
              overflowX: 'clip',
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Card
                  style={{
                    boxShadow: 'none',
                    maxHeight: 'inherit',
                    overflowY: 'scroll',
                  }}
                >
                  <CardHeader
                    align="left"
                    avatar=<BootstrapTooltip
                      title={theMessage}
                      placement="right"
                    >
                      <Avatar sx={{ bgcolor: section.status_color2 }}>
                        {theIcon}
                      </Avatar>
                    </BootstrapTooltip>
                    title=<CardMedia
                      component="img"
                      image={section.client_logo}
                      height="50"
                      sx={{
                        padding: '0',
                        objectFit: 'contain',
                        marginLeft: '0px',
                      }}
                    />
                    action=<div>
                      <BootstrapTooltip
                        title="View Project in Asana"
                        placement="left"
                      >
                        <IconButton
                          aria-label="Asana"
                          onClick={() =>
                            window.open(
                              'https://app.asana.com/0/' + section.gid + '/list'
                            )
                          }
                        >
                          <img
                            src={AsanaLogo}
                            alt="Asana"
                            width={'40px'}
                            height={'37px'}
                          />
                        </IconButton>
                      </BootstrapTooltip>
                      {section.instagantt_link != null ? (
                        <BootstrapTooltip
                          title="View Project in Instagantt"
                          placement="bottom-end"
                        >
                          <IconButton
                            aria-label="Instagantt"
                            onClick={() => window.open(section.instagantt_link)}
                          >
                            <img
                              src={InstaganttIcon}
                              alt="Instagantt"
                              width={'40px'}
                              height={'40px'}
                            />
                          </IconButton>
                        </BootstrapTooltip>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  />
                  <CardContent>
                    <Typography
                      id="transition-modal-title"
                      variant="h4"
                      component="h2"
                      gutterBottom
                      align="center"
                      style={{ padding: '10px 0 30px 0' }}
                    >
                      {section.name}
                    </Typography>
                    {isMobile ? (
                      <TaskList tasks={section.taskscombinedfinal2} />
                    ) : (
                      <TaskCalendar
                        tasks={section.taskscombinedfinal2}
                        first_monday={section.first_monday}
                      />
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openBrief}
            onClose={handleCloseBrief}
            closeAfterTransition
            style={{
              backgroundColor: 'rgb(0 0 0 / 85%)',
              overflowY: 'scroll',
              overflowX: 'clip',
            }}
          >
            <Fade in={openBrief}>
              <Box sx={style}>
                <Card
                  style={{
                    boxShadow: 'none',
                    maxHeight: 'inherit',
                    overflowY: 'scroll',
                  }}
                >
                  <CardHeader
                    align="left"
                    avatar=<BootstrapTooltip
                      title={theMessage}
                      placement="right"
                    >
                      <Avatar sx={{ bgcolor: section.status_color2 }}>
                        {theIcon}
                      </Avatar>
                    </BootstrapTooltip>
                    title=<CardMedia
                      component="img"
                      image={section.client_logo}
                      height="50"
                      sx={{
                        padding: '0',
                        objectFit: 'contain',
                        marginLeft: '0px',
                      }}
                    />
                    action=<div>
                      <BootstrapTooltip
                        title="View Project in Asana"
                        placement="left"
                      >
                        <IconButton
                          aria-label="Asana"
                          onClick={() =>
                            window.open(
                              'https://app.asana.com/0/' + section.gid + '/list'
                            )
                          }
                        >
                          <img
                            src={AsanaLogo}
                            alt="Asana"
                            width={'40px'}
                            height={'37px'}
                          />
                        </IconButton>
                      </BootstrapTooltip>
                      {section.instagantt_link != null ? (
                        <BootstrapTooltip
                          title="View Project in Instagantt"
                          placement="bottom-end"
                        >
                          <IconButton
                            aria-label="Instagantt"
                            onClick={() => window.open(section.instagantt_link)}
                          >
                            <img
                              src={InstaganttIcon}
                              alt="Instagantt"
                              width={'40px'}
                              height={'40px'}
                            />
                          </IconButton>
                        </BootstrapTooltip>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  />
                  <CardContent>
                    <Typography
                      id="transition-modal-title"
                      variant="h4"
                      component="h2"
                      gutterBottom
                      align="center"
                      style={{ padding: '10px 0 30px 0' }}
                    >
                      {section.name}
                    </Typography>
                    <span
                      dangerouslySetInnerHTML={{ __html: section.brief }}
                      style={{ fontFamily: 'Roboto' }}
                    />
                  </CardContent>
                </Card>
              </Box>
            </Fade>
          </Modal>
          <CardContent>
            <Typography variant="h4" component={'div'} gutterBottom noWrap>
              {section.name}
            </Typography>
            {/*<Typography
              component={'div'}
              variant="body2"
              color="text.secondary"
              gutterBottom
              align="center"
            >
              Division: <b>{section.division}</b>
                      </Typography>*/}
            {bizDevBlock == false ? (
              <div>
                <Typography
                  component={'div'}
                  variant="body2"
                  color="text.secondary"
                  gutterBottom
                  align="center"
                >
                  Project Lead: <b>{section.owner_name}</b>
                </Typography>
                {section.accounts != '' ? (
                  <Typography
                    component={'div'}
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                  >
                    Account Lead: <b>{section.accounts}</b>
                  </Typography>
                ) : (
                  <Typography
                    component={'div'}
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                    align="center"
                  >
                    <br />
                  </Typography>
                )}
              </div>
            ) : (
              <div></div>
            )}
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <BootstrapTooltip title={expandTooltip} placement="right-start">
                <ExpandMoreIcon />
              </BootstrapTooltip>
            </ExpandMore>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Typography
                component={'div'}
                variant="body2"
                align="left"
                gutterBottom
              >
                {section.status_title}
              </Typography>
              <Typography
                component={'div'}
                variant="body2"
                color="text.secondary"
                gutterBottom
                align="left"
                style={{
                  whiteSpace: 'pre-wrap',
                  backgroundColor: '#e8f2ff',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                }}
              >
                {section.status_text}
              </Typography>
              {section.completion_date != null && (
                <div>
                  <Typography
                    component={'div'}
                    variant="body2"
                    align="left"
                    gutterBottom
                    style={{
                      paddingTop: '20px',
                    }}
                  >
                    Expected Project Completion:
                  </Typography>
                  <Typography
                    component={'div'}
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                    align="left"
                    style={{
                      whiteSpace: 'pre-wrap',
                      backgroundColor: '#def9ec',
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    {section.completion_date}
                  </Typography>
                </div>
              )}
              {section.next_invoice != null && (
                <div>
                  <Typography
                    component={'div'}
                    variant="body2"
                    align="left"
                    gutterBottom
                    style={{
                      paddingTop: '20px',
                    }}
                  >
                    Next Invoice Milestone:
                  </Typography>
                  <Typography
                    component={'div'}
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                    align="left"
                    style={{
                      whiteSpace: 'pre-wrap',
                      backgroundColor: '#fcfbe0',
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    {section.next_invoice}
                  </Typography>
                </div>
              )}
            </Collapse>
          </CardContent>
        </Card>
      </Item>
    </Grid>
  );
}

export default SectionShow;

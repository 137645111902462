import { TramRounded } from '@mui/icons-material';
import axios from 'axios';

////// *** LOGIN - PASSWORD CHECK *** //////

export const loginPassword = async () => {
  const response = await axios.get(
    'https://app.asana.com/api/1.0/tasks/1204035965662664',
    {
      headers: {
        Authorization:
          'Bearer 1/88550455571935:1d267a3a3589565447c43f7866795c6f',
      },
      params: {},
    }
  );
  const thePassword = response.data.data.notes;

  //console.log(thePassword);
  return thePassword;
};

////// *** GET ALL PROJECT DATA *** //////

export const searchProjects = async () => {
  var divisions;
  const response = await axios.get(
    'https://app.asana.com/api/1.0/portfolios/1203921058861916/items?sort_by=name',
    {
      headers: {
        Authorization:
          'Bearer 1/88550455571935:1d267a3a3589565447c43f7866795c6f',
      },
      params: {},
    }
  );

  const projects = response.data.data;

  ////// *** LOOP THROUGH PROJECTS ARRAY AND GET INDIVIDUAL PROJECT DATA *** //////

  var i;
  for (i = 0; i < projects.length; i++) {
    const response2 = await axios.get(
      'https://app.asana.com/api/1.0/projects/' +
        projects[i].gid +
        '?opt_fields=project_brief,name,custom_fields,current_status,owner.name,due_date,notes,team.name',
      {
        headers: {
          Authorization:
            'Bearer 1/88550455571935:1d267a3a3589565447c43f7866795c6f',
        },
        params: {},
      }
    );
    const projects2 = response2.data.data;

    ////// *** PROJECT NAME *** //////

    projects[i].name = projects[i].name.replace('[[ INT ]] ', '');
    projects[i].name = projects[i].name.replace('[[INT]] ', '');
    projects[i].name = projects[i].name.replace('[INT] ', '');

    console.log(projects[i].name + ' - ' + projects2.team.name);

    projects[i].team_name = projects2.team.name;

    ////// *** PROJECT CUSTOM FIELDS *** //////

    var j;
    for (j = 0; j < projects2.custom_fields.length; j++) {
      if (projects2.custom_fields[j].name == 'TBG Divisions') {
        projects[i].division = projects2.custom_fields[j].display_value;
      } else if (projects2.custom_fields[j].name == 'Accounts') {
        projects[i].accounts = projects2.custom_fields[j].display_value;
      } else if (projects2.custom_fields[j].name == 'Next Invoice') {
        projects[i].next_invoice = projects2.custom_fields[j].display_value;
      } else if (projects2.custom_fields[j].name == 'Instagantt Link') {
        projects[i].instagantt_link = projects2.custom_fields[j].display_value;
      } else if (projects2.custom_fields[j].name == 'Client Logo') {
        projects[i].client_logo = projects2.custom_fields[j].display_value;
      } else if (projects2.custom_fields[j].name == 'Show Subtasks?') {
        projects[i].show_subtasks = projects2.custom_fields[j].display_value;
      }
    }

    ////// *** PROJECT STATUS *** //////

    if (projects2.current_status == null) {
      projects[i].status_title = 'Status Update:';
      projects[i].status_text = '';
      projects[i].status_color = 'white';
    } else {
      //console.log(projects[i].name);
      projects[i].status_title = projects2.current_status.title + ':';
      if (
        (projects2.current_status.text.includes("What we've accomplished") ||
          projects2.current_status.text.includes('Next steps')) &&
        projects2.current_status.text.includes('Summary')
      ) {
        //console.log(projects2.current_status.text);
        if (projects2.current_status.text.includes('Summary\nSummary')) {
          if (
            projects2.current_status.text.includes("What we've accomplished")
          ) {
            projects[i].status_text = projects2.current_status.text
              .split("What we've accomplished")[0]
              .split('Summary\nSummary\n')[1]
              .replaceAll('\n    ', '\n- ')
              .replaceAll('\n-    ', '\n        - ')
              .replaceAll('    ', '    - ')
              .replaceAll('    -     - -  ', '            -  ')
              .replaceAll('            -    -   ', '                 -  ')
              .trim();
          } else if (projects2.current_status.text.includes('Next steps')) {
            projects[i].status_text = projects2.current_status.text
              .split('Next steps')[0]
              .split('Summary\nSummary\n')[1]
              .replaceAll('\n    ', '\n- ')
              .replaceAll('\n-    ', '\n        - ')
              .replaceAll('    ', '    - ')
              .replaceAll('    -     - -  ', '            -  ')
              .replaceAll('            -    -   ', '                 -  ')
              .trim();
          }
        } else {
          if (
            projects2.current_status.text.includes("What we've accomplished")
          ) {
            projects[i].status_text = projects2.current_status.text
              .split("What we've accomplished")[0]
              .split('Summary\n')[1]
              .replaceAll('\n    ', '\n- ')
              .replaceAll('\n-    ', '\n        - ')
              .replaceAll('    ', '    - ')
              .replaceAll('    -     - -  ', '            -  ')
              .replaceAll('            -    -   ', '                 -  ')
              .trim();
          } else if (projects2.current_status.text.includes('Next steps')) {
            projects[i].status_text = projects2.current_status.text
              .split('Next steps')[0]
              .split('Summary\n')[1]
              .replaceAll('\n    ', '\n- ')
              .replaceAll('\n-    ', '\n        - ')
              .replaceAll('    ', '    - ')
              .replaceAll('    -     - -  ', '            -  ')
              .replaceAll('            -    -   ', '                 -  ')
              .trim();
          }
        }
      } else {
        projects[i].status_text = projects2.current_status.text.trim();
      }

      projects[i].status_color = projects2.current_status.color;
    }

    ////// *** PROJECT DATA *** //////

    projects[i].owner_name = projects2.owner.name;
    projects[i].completion_date = projects2.due_date;
    projects[i].scope = projects2.notes;
    if (projects2.project_brief !== undefined) {
      projects[i].brief_id = projects2.project_brief.gid;
    }
    projects[i].first_monday = getThisMonday();
    // .substring(6, 11)
    // .replace('-', '/');

    if (projects[i].brief_id != null) {
      //console.log('project brief is HERE!');

      const responseBrief = await axios.get(
        'https://app.asana.com/api/1.0/project_briefs/' + projects[i].brief_id,
        {
          headers: {
            Authorization:
              'Bearer 1/88550455571935:1d267a3a3589565447c43f7866795c6f',
          },
          params: {},
        }
      );

      projects[i].brief = responseBrief.data.data.html_text;
      projects[i].brief = projects[i].brief.replace('<body>', '');
      projects[i].brief = projects[i].brief.replace('</body>', '');
      projects[i].brief = projects[i].brief.replace('\n', '<br/>');
      //console.log(projects[i].brief);
    }

    ////// *** PROJECT SUBTASKS LOGIC *** //////

    let showSubtasks = '&is_subtask=false';
    let parentTaskFields = '';

    if (projects[i].show_subtasks == 'Yes') {
      showSubtasks = '&is_subtask=true';
      parentTaskFields =
        ',parent.gid,parent.name,parent.due_on,parent.assignee.name,parent.memberships.section.name,parent.memberships.section.gid';
    }

    const response3 = await axios.get(
      'https://app.asana.com/api/1.0/workspaces/15465580567065/tasks/search?opt_fields=due_on,assignee.name,name,memberships.section.name,memberships.section.gid' +
        parentTaskFields +
        '&projects.any=' +
        projects[i].gid +
        '&due_on.before=' +
        get11DaysAfter(getThisMondayAdjusted(-1)) +
        '&due_on.after=' +
        getThisMondayAdjusted(1) +
        '&completed_on=null&sort_by=created_at&sort_ascending=true' +
        showSubtasks,
      {
        headers: {
          Authorization:
            'Bearer 1/88550455571935:1d267a3a3589565447c43f7866795c6f',
        },
        params: {},
      }
    );

    projects[i].taskscombinedfinal2 = response3.data.data;
    //console.log(projects[i].taskscombinedfinal2);

    //projects[i].tasks = response3.data.data;

    if (projects[i].show_subtasks == 'Yes') {
      // ADDED 5/31/23
      // Make the subtasks parent section info, it's info too to stop errors later in the code for undefined memberships arrays
      // Also remove 'subtasks of a subtask' as it causes Section errors (and too hard to pass the Parent Sections down)

      for (var k = 0; k < projects[i].taskscombinedfinal2.length; k++) {
        if (
          projects[i].taskscombinedfinal2[k].parent.memberships[0] == undefined
        ) {
          console.log('Remove: ' + projects[i].taskscombinedfinal2[k].name);
          projects[i].taskscombinedfinal2.splice(k, 1);
          k = k - 1;
        } else {
          projects[i].taskscombinedfinal2[k].memberships =
            projects[i].taskscombinedfinal2[k].parent.memberships;
        }
      }

      /////////////////////////

      // GET ALL OF THE NON-SUBTASK TASKS TOO
      const response6 = await axios.get(
        'https://app.asana.com/api/1.0/workspaces/15465580567065/tasks/search?opt_fields=due_on,assignee.name,name,memberships.section.name,memberships.section.gid&projects.any=' +
          projects[i].gid +
          '&due_on.before=' +
          get11DaysAfter(getThisMondayAdjusted(-1)) +
          '&due_on.after=' +
          getThisMondayAdjusted(1) +
          '&completed_on=null&sort_by=due_date&sort_ascending=true&is_subtask=false',
        {
          headers: {
            Authorization:
              'Bearer 1/88550455571935:1d267a3a3589565447c43f7866795c6f',
          },
          params: {},
        }
      );
      projects[i].tasksNotSubTasks = response6.data.data;
      /* console.log(
        'TASKS THAT ARE NOT SUBTASKS for Project:' + projects[i].name
      );
      console.log(projects[i].tasksNotSubTasks);
      console.log(
        'https://app.asana.com/api/1.0/workspaces/15465580567065/tasks/search?opt_fields=due_on,assignee.name,name&projects.any=' +
          projects[i].gid +
          '&due_on.before=' +
          get11DaysAfter(getThisMondayAdjusted(-1)) +
          '&due_on.after=' +
          getThisMondayAdjusted(1) +
          '&completed_on=null&sort_by=due_date&sort_ascending=true&is_subtask=false'
      ); */
    }

    /*const response4 = await axios.get(
      'https://app.asana.com/api/1.0/workspaces/15465580567065/tasks/search?opt_fields=due_on,assignee.name,name' +
        parentTaskFields +
        '&projects.any=' +
        projects[i].gid +
        '&start_on.before=' +
        get11DaysAfter(getThisMonday()) +
        '&start_on.after=' +
        getThisMonday() +
        '&completed_on=null&sort_by=due_date&sort_ascending=true' +
        showSubtasks,
      {
        headers: {
          Authorization:
            'Bearer 1/88550455571935:1d267a3a3589565447c43f7866795c6f',
        },
        params: {},
      }
    );

    projects[i].tasks2 = response4.data.data;
    projects[i].taskscombined = projects[i].tasks.concat(projects[i].tasks2);

    const response5 = await axios.get(
      'https://app.asana.com/api/1.0/workspaces/15465580567065/tasks/search?opt_fields=due_on,assignee.name,name' +
        parentTaskFields +
        '&projects.any=' +
        projects[i].gid +
        '&due_on.after=' +
        get11DaysAfter(getThisMonday()) +
        '&start_on.before=' +
        getThisMonday() +
        '&completed_on=null&sort_by=due_date&sort_ascending=true' +
        showSubtasks,
      {
        headers: {
          Authorization:
            'Bearer 1/88550455571935:1d267a3a3589565447c43f7866795c6f',
        },
        params: {},
      }
    );

    projects[i].tasks3 = response5.data.data;
    projects[i].taskscombinedfinal = projects[i].taskscombined.concat(
      projects[i].tasks3
    );

    // Remove duplicate tasks
    projects[i].taskscombinedfinal2 = [
      ...new Map(
        projects[i].taskscombinedfinal.map((m) => [m.gid, m])
      ).values(),
    ];*/

    // NEED TO GET ALL NON-SUBTASK TASKS TOO - RUN THE 3 CALLS AGAIN WITH &is_subtask=false ???

    // Get all parent task IDs for this project
    // Remove all tasks that match one of the Parent.gids
    // Take the parent.xxx info to add the parent above the first subtask that it's a parent of

    if (projects[i].show_subtasks == 'Yes') {
      // Sort the Subtasks by Parent and then Due Date
      projects[i].taskscombinedfinal2.sort(
        (a, b) =>
          (a.parent.gid == null) - (b.parent.gid == null) ||
          parseFloat(a.parent.gid) - parseFloat(b.parent.gid) ||
          parseFloat(a.parent.due_on) - parseFloat(b.parent.due_on) ||
          parseFloat(a.due_on) - parseFloat(b.due_on)
      );
    } else {
      // Sort the tasks by Due Date
      projects[i].taskscombinedfinal2.sort(
        (a, b) => parseFloat(a.due_on) - parseFloat(b.due_on)
      );
    }
    //console.log(projects[i].taskscombinedfinal2);

    // Get the current Parent.gid and check if it's the same as the variable
    // If not, add the Parent Task into this Subtasks position in the array and move all along by one
    // Add the current Parent.gid to the variable
    // Skip the counter by 1

    if (
      projects[i].show_subtasks == 'Yes' &&
      projects[i].taskscombinedfinal2.length > 0
    ) {
      projects[i].parentTasks = [];
      for (var k = 0; k < projects[i].taskscombinedfinal2.length; k++) {
        if (projects[i].taskscombinedfinal2[k].parent.gid != null) {
          projects[i].parentTasks.push(
            projects[i].taskscombinedfinal2[k].parent.gid
          );
        }
        projects[i].taskscombinedfinal2[k].name =
          ' - - ' + projects[i].taskscombinedfinal2[k].name;
      }
      // Remove duplicate Parent tasks
      projects[i].uniqueparentTasks = [...new Set(projects[i].parentTasks)];
      //console.log('UNIQUE PARENT TASKS:');
      //console.log(projects[i].uniqueparentTasks);

      if (
        projects[i].tasksNotSubTasks.length > 0 &&
        projects[i].uniqueparentTasks.length > 0
      ) {
        for (var z = 0; z < projects[i].tasksNotSubTasks.length; z++) {
          for (var y = 0; y < projects[i].uniqueparentTasks.length; y++) {
            if (
              projects[i].tasksNotSubTasks[z].gid ==
              projects[i].uniqueparentTasks[y]
            ) {
              //console.log("TASK THAT'S A PARENT TASKS SO NEEDS TO BE REMOVED:");
              //console.log(projects[i].tasksNotSubTasks[z].gid);
              projects[i].tasksNotSubTasks.splice(z, 1);
              z = z - 1;
              break;
            }
          }
        }
      }
    }

    let previousParentTask = '';

    if (projects[i].show_subtasks == 'Yes') {
      if (projects[i].taskscombinedfinal2.length > 0) {
        for (var k = 0; k < projects[i].taskscombinedfinal2.length; k++) {
          if (
            previousParentTask != projects[i].taskscombinedfinal2[k].parent.gid
          ) {
            previousParentTask = projects[i].taskscombinedfinal2[k].parent.gid;

            if (
              projects[i].taskscombinedfinal2[k].parent.due_on <
              get11DaysAfter(getThisMondayAdjusted(-1))
            ) {
              projects[i].taskscombinedfinal2.splice(k, 0, {
                gid: projects[i].taskscombinedfinal2[k].parent.gid,
                name: projects[i].taskscombinedfinal2[k].parent.name,
                due_on: projects[i].taskscombinedfinal2[k].parent.due_on,
                assignee: projects[i].taskscombinedfinal2[k].parent.assignee,
                memberships:
                  projects[i].taskscombinedfinal2[k].parent.memberships,
              });
            } else {
              projects[i].taskscombinedfinal2.splice(k, 0, {
                gid: projects[i].taskscombinedfinal2[k].parent.gid,
                name: projects[i].taskscombinedfinal2[k].parent.name,
                assignee: projects[i].taskscombinedfinal2[k].parent.assignee,
                memberships:
                  projects[i].taskscombinedfinal2[k].parent.memberships,
              });
            }
            k = k + 1;
          }
        }

        projects[i].taskscombinedfinal2 = projects[i].tasksNotSubTasks.concat(
          projects[i].taskscombinedfinal2
        );
      } else {
        projects[i].taskscombinedfinal2 = projects[i].tasksNotSubTasks;
      }
    }

    // NEW CODE FOR SECTIONS - 5-31-23
    /*
    if (
      projects[i].show_subtasks != 'Yes' &&
      projects[i].taskscombinedfinal2.length > 0
    ) {
      for (var k = 0; k < projects[i].taskscombinedfinal2.length; k++) {
        projects[i].taskscombinedfinal2[k].name =
          projects[i].taskscombinedfinal2[k].memberships[0].section.name +
          ': ' +
          projects[i].taskscombinedfinal2[k].name;
      }
    }
*/

    let previousSection = '';

    if (projects[i].taskscombinedfinal2.length > 0) {
      console.log(projects[i].taskscombinedfinal2);
      projects[i].taskscombinedfinal2.sort(
        (a, b) =>
          parseFloat(a.memberships[0].section.gid) -
            parseFloat(b.memberships[0].section.gid) ||
          new Date(a.due_on) - new Date(b.due_on)
      );

      for (var k = 0; k < projects[i].taskscombinedfinal2.length; k++) {
        if (projects[i].taskscombinedfinal2[k].memberships != undefined) {
          if (projects[i].taskscombinedfinal2[k].memberships[0] != null) {
            if (
              projects[i].taskscombinedfinal2[k].memberships[0].section !=
              undefined
            ) {
              if (
                projects[i].taskscombinedfinal2[k].memberships[0].section !=
                null
              ) {
                if (
                  previousSection !=
                  projects[i].taskscombinedfinal2[k].memberships[0].section.name
                ) {
                  previousSection =
                    projects[i].taskscombinedfinal2[k].memberships[0].section
                      .name;
                  projects[i].taskscombinedfinal2.splice(k, 0, {
                    gid: projects[i].taskscombinedfinal2[k].memberships[0]
                      .section.gid,
                    name:
                      '-  ' +
                      projects[i].taskscombinedfinal2[
                        k
                      ].memberships[0].section.name.toUpperCase(),
                  });
                  k = k + 1;
                }
              }
            }
          }
        }
      }
    }

    //////////////////////////////////

    if (projects[i].taskscombinedfinal2.length > 0) {
      for (var k = 0; k < projects[i].taskscombinedfinal2.length; k++) {
        if (projects[i].taskscombinedfinal2[k].due_on != null) {
          projects[i].taskscombinedfinal2[k].due_on_short = betterDueDateFormat(
            projects[i].taskscombinedfinal2[k].due_on
          );
          if (
            projects[i].taskscombinedfinal2[k].due_on_short
              .toString()
              .includes('Mon')
          ) {
            if (projects[i].taskscombinedfinal2[k].due_on < getNextMonday2()) {
              projects[i].taskscombinedfinal2[k].m1 = 'yep';
              //console.log('M1');
            } else {
              projects[i].taskscombinedfinal2[k].m2 = 'yep';
              //console.log('M2');
            }
          } else if (
            projects[i].taskscombinedfinal2[k].due_on_short
              .toString()
              .includes('Tue')
          ) {
            if (projects[i].taskscombinedfinal2[k].due_on < getNextMonday2()) {
              projects[i].taskscombinedfinal2[k].tu1 = 'yep';
              //console.log('Tu1');
            } else {
              projects[i].taskscombinedfinal2[k].tu2 = 'yep';
              //console.log('Tu2');
            }
          } else if (
            projects[i].taskscombinedfinal2[k].due_on_short
              .toString()
              .includes('Wed')
          ) {
            if (projects[i].taskscombinedfinal2[k].due_on < getNextMonday2()) {
              projects[i].taskscombinedfinal2[k].w1 = 'yep';
              //console.log('W1');
            } else {
              projects[i].taskscombinedfinal2[k].w2 = 'yep';
              //console.log('W2');
            }
          } else if (
            projects[i].taskscombinedfinal2[k].due_on_short
              .toString()
              .includes('Thu')
          ) {
            if (projects[i].taskscombinedfinal2[k].due_on < getNextMonday2()) {
              projects[i].taskscombinedfinal2[k].th1 = 'yep';
              //console.log('Th1');
            } else {
              projects[i].taskscombinedfinal2[k].th2 = 'yep';
              //console.log('Th2');
            }
          } else if (
            projects[i].taskscombinedfinal2[k].due_on_short
              .toString()
              .includes('Fri')
          ) {
            if (projects[i].taskscombinedfinal2[k].due_on < getNextMonday2()) {
              projects[i].taskscombinedfinal2[k].f1 = 'yep';
              //console.log('F1');
            } else {
              projects[i].taskscombinedfinal2[k].f2 = 'yep';
              //console.log('F2');
            }
          } else if (
            projects[i].taskscombinedfinal2[k].due_on_short
              .toString()
              .includes('Sat')
          ) {
            if (projects[i].taskscombinedfinal2[k].due_on < getNextMonday2()) {
              projects[i].taskscombinedfinal2[k].Sa = 'yep';
              //console.log('Sa');
            }
          } else if (
            projects[i].taskscombinedfinal2[k].due_on_short
              .toString()
              .includes('Sun')
          ) {
            if (projects[i].taskscombinedfinal2[k].due_on < getNextMonday2()) {
              projects[i].taskscombinedfinal2[k].su = 'yep';
              //console.log('Su');
            }
          }
        }
      }
    }
  }

  //Sort by Team Name and then Project Name (which would be SOW # for those with a #) so that same client projects are grouped together to save jumping around across clients when reading the dashboard:
  //projects.sort((a, b) => (a.team_name > b.team_name ? 1 : -1));
  //projects.sort((a, b) => (a.name > b.name ? 1 : -1));

  const projectsFinal = projects.sort((a, b) => {
    if (a.team_name == b.team_name) {
      return a.name > b.name ? 1 : -1;
    } else {
      return a.team_name > b.team_name ? 1 : -1;
    }
  });

  const projectsStorydriven = projectsFinal.filter(function (el) {
    return el.division == 'Storydriven Way';
  });

  const projectsContent = projectsFinal.filter(function (el) {
    return el.division == 'Content Production';
  });

  const projectsIXL = projectsFinal.filter(function (el) {
    return el.division == 'IXL';
  });

  const projectsExperience = projectsFinal.filter(function (el) {
    return el.division == 'Experience Management';
  });

  const projectsInHouse = projectsFinal.filter(function (el) {
    return el.division == 'In-House';
  });

  const projectsTBGInternal = projectsFinal.filter(function (el) {
    return el.division == 'TBG Internal';
  });

  const projectsBizDev = projectsFinal.filter(function (el) {
    return el.division == 'Biz Dev';
  });

  //console.log(getThisMonday());
  return [
    projectsStorydriven,
    projectsContent,
    projectsIXL,
    projectsExperience,
    projectsInHouse,
    projectsTBGInternal,
    projectsBizDev,
  ];
};

export const searchTasks = async () => {};

function getNextMonday() {
  var today = new Date();
  var day = today.getDay();
  //console.log(day);
  var daysUntilMonday = (7 - day) % 7;
  //console.log(daysUntilMonday);
  return new Date(today.getTime() - daysUntilMonday * 24 * 60 * 60 * 1000)
    .toISOString()
    .substring(0, 10);
}

function getThisMonday() {
  var today = new Date();
  var day = today.getDay();
  //console.log(today.getTime());
  //console.log(day);
  if (day === 0) {
    day = 7;
  }
  var daysToGoBack = day - 1;
  //console.log(daysToGoBack);
  //console.log(new Date(today.getTime()).toISOString());
  //console.log(today.getTime() - daysToGoBack * 24 * 60 * 60 * 1000);
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  /*console.log(
    new Date(
      today.getTime() - tzoffset - daysToGoBack * 24 * 60 * 60 * 1000
    ).toISOString()
  );*/
  return new Date(
    today.getTime() - tzoffset - daysToGoBack * 24 * 60 * 60 * 1000
  )
    .toISOString()
    .substring(0, 10);
}

function getNextMonday2() {
  var today = new Date();
  var day = today.getDay();
  //console.log(day);
  if (day === 0) {
    day = 7;
  }
  var daysToGoForward = 8 - day;
  //console.log(daysToGoBack);
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(
    today.getTime() - tzoffset + daysToGoForward * 24 * 60 * 60 * 1000
  )
    .toISOString()
    .substring(0, 10);
}

function get11DaysAfter(date) {
  var date = new Date(date);
  date.setDate(date.getDate() + 11);
  return date.toISOString().split('T')[0];
}

function betterDueDateFormat(dateString) {
  let date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  const options = {
    weekday: 'short',
    month: 'numeric',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
}

function getThisMondayAdjusted(day_adjustment) {
  var today = new Date();
  var day = today.getDay();
  //console.log(today.getTime());
  //console.log(day);
  if (day === 0) {
    day = 7;
  }
  var daysToGoBack = day - 1 + day_adjustment;
  //console.log(daysToGoBack);
  //console.log(new Date(today.getTime()).toISOString());
  //console.log(today.getTime() - daysToGoBack * 24 * 60 * 60 * 1000);
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  /*console.log(
    new Date(
      today.getTime() - tzoffset - daysToGoBack * 24 * 60 * 60 * 1000
    ).toISOString()
  );*/
  return new Date(
    today.getTime() - tzoffset - daysToGoBack * 24 * 60 * 60 * 1000
  )
    .toISOString()
    .substring(0, 10);
}

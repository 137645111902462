import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
//import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

function TaskShow({ task }) {
  return (
    <TableRow>
      <StyledTableCell>{task.name}</StyledTableCell>
      <StyledTableCell align="center">{task.due_on_short}</StyledTableCell>
      <StyledTableCell align="right">
        {task.assignee != null && task.assignee.name}
      </StyledTableCell>
    </TableRow>
  );
}

export default TaskShow;
